export const eventData = [
  {
    title: "Book club",
    author: "Susan Sontag",
    book: "Notes on Camp",
    date: "17.03.22",
    image: require("../assets/posters/Lez Read _1.png"),
    alt: "Lez Read Sontag",
  },
  {
    title: "Book club",
    author: "Gertrude Stein",
    book: "Food",
    date: "21.04.22",
    image: require("../assets/posters/Lez Read _2.png"),
    alt: "Lez Read Stein",
  },
  {
    title: "Book club",
    author: "Audre Lorde",
    book: "The Master's Tools",
    date: "19.05.22",
    image: require("../assets/posters/Lez Read _3.png"),
    alt: "Lez Read Lorde",
  },
  {
    title: "Book club",
    author: "Anais Nin",
    book: "The Veiled Woman",
    date: "21.07.22",
    image: require("../assets/posters/Lez Read _4.png"),
    alt: "Lez Read Nin",
  },
  {
    title: "Book club",
    description: "An Interview with Huw Lemmey and Ben Miller",
    date: "04.06.22",
    image: require("../assets/posters/bad-gays.png"),
    alt: "A Paned with Bad Gays",
  },

  {
    title: "Book club",
    author: "Kathy Acker",
    book: "New York City in 1979",
    date: "18.08.22",
    image: require("../assets/posters/Lez Read _5.png"),
    alt: "Lez Read Acker",
  },
  {
    title: "Book club",
    author: "Daphne Du Maurier",
    book: "The Breakthrough",
    date: "27.09.22 and 28.09.22",
    image: require("../assets/posters/Lez Read _6.png"),
    alt: "Lez Read Du Maurier",
  },
  {
    title: "Book club",
    author: "Carson McCullers",
    book: "The Haunted Boy",
    date: "20.10.22",
    image: require("../assets/posters/Lez Read _7.png"),
    alt: "Lez Read McCullers",
  },
  {
    title: "Book club",
    author: "Sappho",
    book: "Come Close",
    date: "24.11.22",
    image: require("../assets/posters/Lez Read _8.png"),
    alt: "Lez Read Sappho",
  },

  {
    title: "Book club",
    description: "Christmas Party",
    date: "21.02.23",
    image: require("../assets/posters/Lez Read _9.png"),
    alt: "Lez Read Xmas",
  },
  {
    title: "Book club",
    author: "Valerie Solanas",
    book: "SCUM Manifesto",
    date: "21.02.23",
    image: require("../assets/posters/Lez Read _10.png"),
    alt: "Lez Read Solanas",
  },
  {
    title: "Book club",
    author: "Virginia Woolf",
    book: "Flush",
    date: "28.03.23",
    image: require("../assets/posters/Lez Read _11.png"),
    alt: "Lez Read Woolf",
  },
  {
    title: "Book club",
    author: "Emily Dickinson",
    book: "My Life Had Stood A Loaded Gun",
    date: "18.05.23",
    image: require("../assets/posters/Lez Read _12.png"),
    alt: "Lez Read Dickinson",
  },
  {
    title: "Book club",
    description: "An Interview with Rachel Dawson",
    date: "25.05.23",
    image: require("../assets/posters/neon-roses.png"),
    alt: "Neon Roses Book Launch",
  },
  {
    title: "Book club",
    author: "Radclyffe Hall",
    book: "The Well of Loneliness",
    date: "20.06.23",
    image: require("../assets/posters/Lez Read _13.png"),
    alt: "Lez Read Hall",
  },
  {
    title: "Book club",
    author: "Alison Bechdel",
    book: "Dykes to Watch Out For",
    date: "23.08.23",
    image: require("../assets/posters/Lez Read _14.png"),
    alt: "Lez Read Bechdel",
  },
  {
    title: "Book club",
    author: "Rachel Dawson",
    book: "Neon Roses",
    date: "21.09.23",
    image: require("../assets/posters/Lez Read _15.png"),
    alt: "Lez Read Dawson",
  },
  {
    title: "Book club",
    description: "A book club outing to Hay-on-Wye!",
    date: "30.09.23",
    image: require("../assets/posters/hay-on-wye.jpg"),
    alt: "Trip to Gay on Wye",
  },
  {
    title: "Book club",
    author: "Patricia Highsmith",
    book: "Little Tales of Misogyny",
    date: "16.10.23",
    image: require("../assets/posters/Lez Read _16.png"),
    alt: "Lez Read Highsmith",
  },
  {
    title: "Book club",
    author: "Cipher Press",
    book: "Unreal Sex",
    date: "30.11.23",
    image: require("../assets/posters/Lez Read _17.png"),
    alt: "Lez Read Unreal Sex",
  },
  {
    title: "Book club",
    description: "Christmas Party",
    date: "21.02.23",
    image: require("../assets/posters/Lez Read _18.png"),
    alt: "Lez Read Xmas",
  },
  {
    title: "Book club",
    description: "Online Event reading Palestinian authors and poets",
    date: "21.02.23",
    image: require("../assets/posters/Lez Read _19.png"),
    alt: "Lez Read Palestine",
  },
  {
    title: "Book club",
    author: "Edith Wharton",
    book: "The Reckoning",
    date: "06.03.24",
    image: require("../assets/posters/Lez Read _20.png"),
    alt: "Lez Read Wharton",
  },
  {
    title: "Book club",
    author: "Jeanette Winterson",
    book: "Love",
    date: "18.04.24",
    image: require("../assets/posters/Lez Read _21.png"),
    alt: "Lez Read Winterson",
  },
  {
    title: "Book club",
    description: "An Interview with Eleanor Medhurst",
    date: "11.07.24",
    image: require("../assets/posters/medhurst.png"),
    alt: "Paned â Eleanor Medhurst",
  },
];
